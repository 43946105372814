export const API_ORIGIN = 'https://www.afisha.uz';
export const DEFAULT_PREVIEW = '/assets/images/afishauz-cover.png';

export type Locale = 'ru' | 'uz';
export const locales: Locale[] = ['ru', 'uz'] as const;

export interface BaseEntity {
  '@id': string;
  '@type': string;
}

export type Translations<T> = Record<Locale, Omit<T, 'translations'>>;

export type OrderType = 'ASC' | 'DESC';

export type RelationGroup =
  | 'material:item:read'
  | 'material:main_media_object'
  | 'media_object:read'
  | 'media_object:variants'
  | 'material:event_schedules'
  | 'material:permalink_canonical'
  | 'video:main_media_object'
  | 'performance:main_media_object'
  | 'district:read'
  | 'material:sections'
  | 'material:tags'
  | 'place_type:read'
  | 'place:read'
  | 'place:entities'
  | 'place:images'
  | 'city:read'
  | 'section:read'
  | 'staff_member:read'
  | 'material:places'
  | 'material:videos'
  | 'video:read'
  | 'video:genres'
  | 'genre:read'
  | 'video:directors'
  | 'celebrity:read'
  | 'material:performances'
  | 'performance:read'
  | 'performance:directors'
  | 'performance:genres'
  | 'video:images'
  | 'place:main_media_object'
  | 'air_carrier:read'
  | 'menu:items'
  | 'menu_item:read'
  | 'menu_item:child_menu_items'
  | 'performance:celebrities'
  | 'performance:images'
  | 'performance:item:read'
  | 'place_type:type_fields'
  | 'place_type_field:read'
  | 'payment_method:read'
  | 'place:attributes'
  | 'place:contacts'
  | 'place:custom_fields'
  | 'place:payment_methods'
  | 'place:sub_places'
  | 'place:subway_stations'
  | 'place:item:read'
  | 'place:menus'
  | 'place:types'
  | 'place_chain:read'
  | 'section:template'
  | 'section:featured_materials'
  | 'section:featured_materials_by_locale'
  | 'material:featured_in_sections'
  | 'template:read'
  | 'material:read'
  | 'theme_script:read'
  | 'theme_stylesheet:read'
  | 'video:celebrities'
  | 'video:item:read'
  | 'video:trailers'
  | 'video_trailer:read'
  | 'video:first_image'
  | 'video:countries'
  | 'video:featured'
  | 'country:read'
  | 'video:materials'
  | 'material:link'
  | 'material:intro'
  | 'user:read'
  | 'material:related_materials'
  | 'material:photoset_images'
  | 'video:shows_tickets'
  | 'football_match:teams'
  | 'football_team:read'
  | 'football_match:stage_group'
  | 'football_championship_group:read'
  | 'football_championship_stage:read'
  | 'football_championship_group:standings'
  | 'football_championship_standing:read'
  | 'football_championship_standing:team'
  | 'football_team:read'
  | 'football_match:stats'
  | 'football_match:lineups'
  | 'football_match_lineup:read'
  | 'football_match:events'
  | 'football_match_event:read'
  | 'football_player:read'
  | 'football_match_lineup:player'
  | 'football_match_event:player';
